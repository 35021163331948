<template>
    <div class="feedBackBox">
        <div style="margin-bottom:30px">
            <el-select v-for="item in options" v-model="sihft[`value${item.id}`]" multiple
                :placeholder="item.placeholder" style="width: 150px;margin-right: 20px;">
                <el-option v-for="optionItem in item.options" :key="optionItem.value" :label="optionItem.label"
                    :value="optionItem.value" />
            </el-select>
            <el-button type="primary" size="small" @click="getFeedback('filter')">确定筛选</el-button>
            <!-- <el-button type="primary" size="small" @click="refresh">刷新</el-button> -->
        </div>

        <div v-for="(item, index) in feedback" class="feedbackItem">
            <div class="titleItem">
                <div class="left" @click="showAndHide(item)">
                    <p class="feedbackTitle">{{ item.feedbackTitle ? item.feedbackTitle : "..." }}</p>

                    <div class="tags">
                        <el-button v-if="getFeedbackStatus(item.feedbackStatus,).feedbackStatus != ''"
                            :type="item.feedbackStatus == 1 ? 'danger' : 'success'" size="small" plain disabled>
                            {{ getFeedbackStatus(item.feedbackStatus,).feedbackStatus }}
                        </el-button>
                        <el-button v-if="getFeedbackStatus(0, item.feedbackReason).feedbackReason != ''" type="warning"
                            size="small" disabled>
                            {{ getFeedbackStatus(0, item.feedbackReason).feedbackReason }}
                        </el-button>
                        <el-button v-if="getFeedbackStatus(0, 0, item.feedbackPart).feedbackPart != ''" type="primary"
                            size="small" round disabled>
                            {{ getFeedbackStatus(0, 0, item.feedbackPart).feedbackPart }}
                        </el-button>
                    </div>
                </div>


                <div class=" right">
                    <p>{{ formatUtcTime(item.feedbackTime) }}</p>

                    <div class="rightBtn">
                        <div class="rightest">
                            <el-tooltip class="box-item" effect="dark" v-if="!item.isUp" content="置顶" placement="top">
                                <el-button type="success" size="small" @click="toTop(item)">
                                    <el-icon>
                                        <Top />
                                    </el-icon>
                                </el-button>
                            </el-tooltip>

                            <el-tooltip class="box-item" effect="dark" v-if="item.isUp" content="取消置顶就默认解决了哦!"
                                placement="top">
                                <el-button type="success" size="small" @click="toBottom(item)">
                                    <el-icon>
                                        <Bottom />
                                    </el-icon>
                                </el-button>
                            </el-tooltip>


                            <el-popconfirm title="确定要删除嘛?" @confirm="deleteHandle(item, index)" @cancel="">
                                <template #reference>
                                    <el-button type="danger" size="small">
                                        <el-icon>
                                            <DeleteFilled />
                                        </el-icon>
                                    </el-button>
                                </template>
                            </el-popconfirm>

                        </div>

                        <el-radio-group v-if="!item.isUp" v-model="item.radio" size="small" style="margin-right: 20px;"
                            ref="statusItem">
                            <el-radio-button v-for="statusItem in statusBtn " :label="statusItem.value"
                                @change="changeStatus({ statusItem, item })" :key="statusItem.id" />
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <el-collapse v-model="activeName" v-show="item.isShow">
                <el-collapse-item title="详细说明" name="2">
                    <div class="content">
                        {{ item.feedbackContent }}
                    </div>
                </el-collapse-item>
                <el-collapse-item title="图片" name="3">
                    <div class="content" v-if="item.feedbackImgList && item.feedbackImgList.length">
                        <enlarge-img v-if="showImg" @clickit="closeImg" :imgSrc="imgSrc"></enlarge-img>
                        <img v-for="(imgItem, index) in item.feedbackImgList" :src="imgItem.feedback_img"
                            style="width:100px;height:100px;margin-right: 20px;" @click="clickImg($event)">
                    </div>
                    <div v-else>
                        <p style="margin-left:20px">暂时没有图片哦！</p>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="联系方式" name="4">
                    <div class="content">
                        {{ item.feedbackContact }}
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-pagination class="pagination" :current-page="page" :page-size="size" :pager-count="5"
            layout="prev, pager, next" :total="totalCount" @current-change="pageChange" />
    </div>
</template>
<script>
import { statusBtn, options } from './promises'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import enlargeImg from '../EnlargeImg.vue'
export default {
    data() {
        return {
            options: [],//列表数据
            statusBtn: [],
            actived: -1,
            showImg: false,
            imgSrc: ''
        }
    },
    components: { 'enlarge-img': enlargeImg },
    async created() {
        await this.getFeedback()//初始化数据
        options.forEach((item, index) => {
            this.options.push(item)
        })
        statusBtn.forEach((item, index) => {
            this.statusBtn.push(item)
        })

    },
    computed: {
        ...mapState({
            totalCount: (state) => { return state.feedBackProblemModules.totalCount },
            size: (state) => { return state.feedBackProblemModules.size },
            page: (state) => { return state.feedBackProblemModules.page },
            feedback: (state) => { return state.feedBackProblemModules.feedback },
            sihft: (state) => { return state.feedBackProblemModules.sihft }
        })
    },
    methods: {
        ...mapMutations('feedBackProblemModules', ['getTableData']),
        ...mapActions('feedBackProblemModules', ['deleteHandle', 'toTop', 'toBottom', 'changeStatus', 'getImage', 'getFeedback', 'pageChange']),
        showAndHide(item) {
            if (!item.isShow) {
                item.isShow = true
                this.getImage(item) //提前加载图片
            } else {
                item.isShow = !item.isShow
            }
            console.log("666item", item)
        },
        clickImg(e) {
            this.showImg = true;
            this.imgSrc = e.currentTarget.src;// 获取当前图片地址

        },
        closeImg() {
            this.showImg = false;
        },
        formatUtcTime(v) {//格式化utc时间
            let date = new Date(v);
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        },
        refresh() {
            window.location.href = window.location.href
        },
        getFeedbackStatus(feedbackStatus, feedbackReason, feedbackPart) {
            let obj = {}
            if (feedbackStatus == 1) {
                obj.feedbackStatus = "未解决"
            } else if (feedbackStatus == 2) {
                obj.feedbackStatus = "已解决"
            } else {
                obj.feedbackStatus = ''
            }

            if (feedbackReason == 0) {
                obj.feedbackReason = "建议"
            } else if (feedbackReason == 1) {
                obj.feedbackReason = "bug应用"
            } else {
                obj.feedbackReason = ''
            }

            if (feedbackPart == 3) {
                obj.feedbackPart = "AlphaIDE"
            } else if (feedbackPart == 2) {
                obj.feedbackPart = "LakeSoul"
            } else if (feedbackPart == 1) {
                obj.feedbackPart = "Metaspore"
            } else if (feedbackPart == 0) {
                obj.feedbackPart = "其他"
            } else {
                obj.feedbackPart = ''
            }
            return obj
        }
    }
}
</script>
<style scoped>
.feedBackBox {
    padding: 30px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.feedbackItem {
    line-height: 48px;
    background-color: #ffffff;
    box-shadow: -6px 6px 15px 5px gray;
    margin-bottom: 5px;
    border-radius: 8px;
    padding: 15px 20px;
}

.feedBackBox /deep/ .el-collapse-item__header {
    font-weight: 300;
    font-size: 15px;
}

.el-collapse-item /deep/ .el-collapse-item__content {
    padding: 0
}

.feedBackBox /deep/ .el-collapse-item__header {
    padding: 0 10px;
}

.content {
    padding: 10px;
    font-size: 14px;
    position: relative;
    z-index: 1;
}

.titleItem,
.right,
.rightBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right {
    width: 24%;
}

.rightBtn {
    width: 50%;
    flex-direction: row-reverse;
}

.rightest {
    display: flex;
    align-items: center;
}

.left {
    cursor: pointer;
    flex-grow: 1;
    display: flex;
    /* background-color: antiquewhite; */
}

.feedbackTitle {
    width: 40%;
    /* background-color: aquamarine; */
    font-size: 16px;
    font-weight: 500;
}

.tags {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: blue; */
    padding-right: 200px;
}
</style>
