
let options1 =
{
    id: 0,
    placeholder: "请选择问题状态",
    options: [
        {
            value: '1',
            label: '未解决',
        },
        {
            value: '2',
            label: '已解决',
        },
    ]
}
let options2 = {
    id: 1,
    placeholder: "请选择问题类型",
    options: [
        {
            value: '0',
            label: '建议',
        },
        {
            value: '1',
            label: 'bug应用',
        },
    ]
}

let options3 = {
    id: 2,
    placeholder: "请选择应用",
    options: [
        {
            value: '3',
            label: 'AlphaIDE',
        },
        {
            value: '2',
            label: 'LakeSoul',
        },
        {
            value: '1',
            label: 'Metaspore',
        },
        {
            value: '0',
            label: '其他',
        },
    ]
}
export let options = [
    options1,
    options2,
    options3,
]
export let statusBtn = [
    {
        id: 1,
        type: 'danger',
        value: '未解决'
    },
    {
        id: 2,
        type: 'success',
        value: '已解决'
    }
]
