<template>
    <!-- 过渡动画 -->
    <div class="img-view" @click="bigImg">
        <!-- 遮罩层 -->
        <div class="img-layer"></div>
        <div class="img">
            <img :src="imgSrc">
        </div>

    </div>
</template>
<script>
export default {
    props: ['imgSrc'],//接受图片地址
    methods: {
        bigImg() {
            // 发送事件
            this.$emit('clickit')
        }
    }
}
</script>
<style scoped>
@keyframes fadenum {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }

}

.img-view {
    animation: fadenum 0.5s ease-out 1;
}

.img-view,
.img-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*遮罩层样式*/
.img-layer {
    z-index: 999;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
}

/*不限制图片大小，实现居中*/
img {
    max-width: 95%;
    max-height: 95%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
}
</style>